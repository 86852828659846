<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <div class="textCard">
          <!-- <h1>Cam kết bảo mật</h1> -->
          <!-- <div class="title-line"></div> -->
          <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import importedImg1 from "@/page/controlesempresarialessas/components/img/4_1.png";

export default {
  data() {
    return {
      altText: "protocolTitle",
      importedImg1: importedImg1,
      text: `"Esta Política de Privacidad ("Política") se aplica a esta aplicación ("Aplicación"). Informa a los usuarios de la Aplicación y los servicios que proporciona ("usted") qué información recopila la Aplicación y cómo la Aplicación utiliza y comparte esta información.


La Aplicación recopila información de los usuarios de la Aplicación que puede identificar a un individuo específico ("Información de identificación personal"), así como información anónima que no puede asociarse con un individuo específico ("Información de identificación no personal").

La Aplicación recopila información de identificación personal cuando usted proporciona información de identificación personal a través del uso de la Aplicación, como al completar una o más etapas de una solicitud en línea (la "Solicitud") con un prestamista que participa en la red de prestamistas de la Aplicación (la " Aplicación") información. "Solicitar") para proporcionar ofertas de préstamos. "Socios prestamistas") o enviar preguntas a los solicitantes por correo electrónico. Los tipos de información de identificación personal recopilada por esta aplicación incluyen: nombre, dirección física, número de teléfono, número de seguro social, dirección de correo electrónico, fecha de nacimiento, número de licencia de conducir y estado, información de propiedad de la vivienda, estado de ciudadanía, información de empleo, servicio militar activo, información militar, monto y frecuencia de pago e información de cuenta bancaria.

Los tipos de información de identificación no personal recopilada por la Aplicación (ya sea directamente mediante el uso de "cookies" o mediante el uso de sistemas de seguimiento de terceros) incluyen: La dirección IP del dispositivo que realiza la solicitud; Su tipo de dispositivo e idioma ; Tiempos de acceso; Información sobre el acceso del usuario a la Aplicación Información Información sobre áreas de la aplicación; información sobre las páginas que los usuarios pueden elegir visitar dentro de la aplicación. Las direcciones IP normalmente no están asociadas con su dispositivo personal, sino con la puerta de enlace a través de la cual accede a Internet. Las direcciones IP no están asociadas con información de identificación personal. Una "cookie" es un fragmento de información que una aplicación envía a su dispositivo cuando la visualiza para recordar quién es usted sin identificarlo personalmente.

La Aplicación puede compartir esta información de identificación no personal con sus proveedores y otros terceros para estadísticas de la aplicación y análisis de tendencias. Esta información no está vinculada a ninguna información de identificación personal.

La Aplicación puede permitir que terceros, incluidos socios publicitarios y prestamistas personales, muestren anuncios en la Aplicación. Estas empresas pueden utilizar tecnologías de seguimiento, incluidas cookies, para recopilar información sobre los usuarios de la aplicación que ven o interactúan con estos anuncios. La Aplicación no proporciona información de identificación personal a estos terceros.

La Aplicación utiliza la información de identificación personal que usted proporciona a través de la Aplicación para entregar los productos o servicios que ha solicitado; para brindarle servicio al cliente; por correo electrónico, correo postal, teléfono y/o dispositivo móvil (incluso a través de servicios de mensajes de texto o "SMS"). "). "") comunicarse con usted sobre productos o servicios de la Aplicación, socios prestamistas u otros terceros que puedan ser de su interés; mostrar anuncios adaptados a sus intereses o antecedentes; utilizar la Aplicación para análisis de datos y estadísticos; hacer cumplir los términos de la Aplicación y condiciones y realizar cualquier otra función que se le describa cuando la Aplicación recopile información.

La Aplicación puede revisar y utilizar su información financiera y su número de Seguro Social para conectarlo con socios prestamistas u otros prestamistas externos con quienes la Aplicación tiene una relación comercial. La aplicación también puede revisar su información financiera y su número de Seguro Social para verificar su identidad y protegerlo de transacciones potencialmente fraudulentas.

La Aplicación puede compartir su información de identificación personal (incluyendo, entre otros, la información de su cuenta financiera y su número de Seguro Social) con socios prestamistas y otros prestamistas externos con quienes la Aplicación tiene una relación comercial. Estos prestamistas externos pueden utilizar su información de identificación personal para brindarle un préstamo personal o contactarlo con respecto a otras ofertas comerciales o de marketing. Además, la Aplicación puede compartir su información de identificación personal (incluida la información de su cuenta financiera y su número de Seguro Social) con vendedores externos no afiliados y administradores de listados con quienes la Aplicación tiene una relación comercial.

Los terceros con los que la Aplicación comparte información de identificación personal pueden tener sus propias políticas que describen cómo usan y divulgan su información. Estas políticas regirán el uso, procesamiento y divulgación de su información después de que la Aplicación transfiera o comparta su información con un tercero como se describe en esta Política o en los Términos y condiciones de la Aplicación.

La aplicación divulgará su información de identificación personal en respuesta a una citación o solicitud de investigación similar, orden judicial o solicitud de cooperación de las fuerzas del orden u otras agencias gubernamentales; para establecer o ejercer nuestros derechos legales; para defendernos de reclamaciones legales; o según sea necesario. por ley. La Aplicación divulgará su Información de identificación personal cuando la Aplicación crea que es necesario investigar, prevenir o tomar medidas contra actividades ilegales, sospecha de fraude u otras conductas indebidas; para proteger y defender a la empresa matriz de la Aplicación, sus derechos, propiedad o seguridad. de empleados, usuarios u otros; o para hacer cumplir los términos y condiciones u otros acuerdos o políticas.

Además, la Aplicación puede transferir información de identificación personal a una entidad que adquiere todas o sustancialmente todas las acciones o activos de su empresa matriz.

La Aplicación puede proporcionar enlaces o redirigirlo automáticamente a fuentes de terceros que no operan de acuerdo con esta Política. Por ejemplo, si hace clic en un anuncio presentado en nuestra Aplicación, es posible que se le dirija a fuentes que no son operadas ni controladas por esa Aplicación. Debe revisar las declaraciones de privacidad y los términos de uso de todas las fuentes de terceros que visite. Estas fuentes de terceros pueden solicitar y recopilar información sobre usted de forma independiente. De vez en cuando, estas fuentes de terceros pueden proporcionar a la Aplicación información sobre su uso de estas fuentes.

Las políticas, el contenido, la información, las promociones, las divulgaciones, las exenciones de responsabilidad y las características de la Aplicación pueden revisarse, modificarse, actualizarse y/o complementarse en cualquier momento sin previo aviso y a exclusivo criterio de la Aplicación. Los cambios a esta Política se publicarán en la Aplicación, y la Aplicación revisará la fecha de "entrada en vigor" en la parte superior de esta Política. Debe revisar esta Política cada vez que acceda a la Aplicación. Si la Aplicación realiza algún cambio sustancial en la forma en que recopila, usa y/o comparte información personal que pueda afectarlo, la Aplicación se lo notificará publicando los cambios de manera destacada en esta Aplicación.

La Aplicación no está destinada a ser utilizada por personas menores de 18 años o la mayoría de edad legal en su jurisdicción. La Aplicación no recopila intencionalmente información de identificación personal de niños menores de 18 años.

La Aplicación ha implementado medidas de seguridad físicas, de procedimiento y técnicas para ayudar a prevenir el acceso no autorizado y el uso inadecuado de la información recopilada por la Aplicación. Por ejemplo, sólo los empleados autorizados y los terceros autorizados tienen acceso a la información de identificación personal, y pueden hacerlo únicamente para funciones comerciales permitidas. Ninguna aplicación o red de Internet puede ser completamente segura. Aunque la Aplicación toma medidas para proteger su información, no garantiza (y usted no debe esperar) que su información personal, búsquedas u otras comunicaciones permanezcan siempre seguras.

Si desea revisar y/o solicitar cambios en la información de identificación personal que una Aplicación ha recopilado a través de su uso, comuníquese con la Aplicación escribiéndonos o enviándonos un correo electrónico a la dirección que aparece a continuación. "`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
img {
  display: block;
}
.imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1,
.img2 {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2 {
  width: 100%;
  margin: 0;
}
.img2{
  padding: 0 220px;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3f4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}
.textBox {
  margin: 70px 0 150px;
  width: 90%;
  font-size: 18px;
  color: #333333;
}
.textCard {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 32px;
  box-shadow: 0 4px 22.7px 1px rgba(0, 0, 0, 0.25);
  margin: 10px 0 200px;
}

.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0baf60;
  margin: 0 500px;
}
.img1 {
  /* margin-top: 50px; */
  /* padding: 0 200px; */
}
</style>